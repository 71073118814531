import shoopp from '../../assets/projects-img/shoopp.png'
import redditapp from '../../assets/projects-img/redditapp.png'

export const projects = [
    {
        name: 'SHOOPP',
        link: 'https://shoopps.netlify.app',
        image: shoopp,
        description: 'An e-commerce fullstack web application built using MERN stack deployed on railway and netlify',
        route: 'shoopp'
    },
    {
        name: 'REDdit APP',
        link: 'https://redditapp.surge.sh/',
        image: redditapp,
        description: 'A web app utilising the Reddit API to showcase and search for posts',
         route: 'redditapp'   
    }
]